import React, {useEffect, useState} from "react";
import {
    CircularProgress,
    Link,
    Alert, Button
} from "@mui/material";
import {PhotoCard} from "../components/PhotoCard";
import {UserProfile} from "../App.routes";
import {getPhotos} from "../services/PhotosService";
import {usePhotoFilter} from "../providers/PhotoProvider";
import {PhotoImageData} from "../models/PhotoImageData";
import { useNavigate } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import {Permissions} from "../components/LoginTutorial";

export interface PhotosPageProps {
    profile:UserProfile,
    token:string
}
export const PhotosPage = (props:PhotosPageProps) => {
    const [photos, setPhotos] = useState<PhotoImageData[]>([]);
    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState('DONE');
    const [nextPageToken, setNextPageToken] = useState(null);
    const [isLastPage, setIsLastPage] = useState(false);
    const [isEmpty, setIsEmpty] = useState(false);
    const navigate = useNavigate();
    const {setPhotoFilter} = usePhotoFilter();
    useEffect(() => {
        queryPhotos();
    },[props.token])

    const queryPhotos = () => {
        setLoading(true);
        getPhotos(props.token,nextPageToken).then(res => {
            if(!res.success) {
                setStatus(res.status);
            } else {
                if(Array.isArray(res.data?.mediaItems)){
                    setPhotos(p => [...p,...res.data.mediaItems]);
                } else {
                    setIsEmpty(true);
                }
                if(res.data.nextPageToken) {
                    setNextPageToken(res.data.nextPageToken);
                } else {
                    setIsLastPage(true);
                }
            }
        }).finally(() => setLoading(false));
    }
    const handleImageClick = (data: PhotoImageData) => {
        setPhotoFilter(data);
        navigate('filter');
    }
    const fetchData = () => {
        queryPhotos();
    }
    const logout = () => {
        localStorage.removeItem('profile');
        navigate('login');
    }
    return (
      <>
          <main>
              <div style={{width: '100%', minHeight: 829}}>
                  {
                      status.includes('PERMISSION_DENIED') ? (
                          <>
                          <Alert severity={'error'}>Ups! Parece que no tenemos los permisos necesarios</Alert>
                          <div style={{padding:'10px'}}>
                              <Permissions/>
                          </div>
                          <div style={{display:'flex',justifyContent:'center'}}>
                            <Button variant={'contained'} color={'primary'} onClick={logout}> Try again</Button>
                          </div>
                          </>
                      ) : null
                  }
                  {
                      loading ? (
                          <div style={{width:'100%',height:'100%',display:'flex',justifyContent:'center',alignItems:'center', alignContent:'center',minHeight:'300px'}}>
                              <CircularProgress />
                          </div>
                      ) : (
                          <>
                              {/*<Filters onChange={e => console.log(e)}/>*/}
                              { status === 'UNAUTHENTICATED' && <UnauthenticatedUser/> }
                              {
                                  isEmpty ? (
                                    <Alert severity={'warning'}>Tu cuenta de google está vacía, puedes verificar que tienes información cargada aquí:&nbsp;
                                        <Link href={"https://photos.google.com/"} target={'blank'}>Google fotos</Link></Alert>
                                  ) : null
                              }
                              {
                                  !isEmpty && status === 'DONE' ? (
                                          <div style={{
                                              width: '100%',
                                              height: '100%',
                                              display: 'flex',
                                              justifyContent: 'center',
                                              alignItems: 'center',
                                              alignContent: 'center',
                                              minHeight: '300px'
                                          }}>
                                              <CircularProgress/>
                                          </div>
                                      // <InfiniteScroll
                                      //     dataLength={photos.length} //This is important field to render the next data
                                      //     next={fetchData}
                                      //     hasMore={!isLastPage}
                                      //     loader={<h4>Loading...</h4>}
                                      //     endMessage={ <Alert severity={'info'}>Yay! Has llegado al final!</Alert> }
                                      // >
                                      //     <div style={{display: 'flex', flexWrap:'wrap', justifyContent:'space-evenly'}}>
                                      //         {
                                      //             photos.map((photo,idx) => <PhotoCard key={idx} data={photo} handleClick={handleImageClick}/>)
                                      //         }
                                      //     </div>
                                      // </InfiniteScroll>

                                  ) : null
                              }
                          </>
                      )
                  }
              </div>
          </main>
      </>
    );
}

const UnauthenticatedUser = () => {
    return (
        <Alert severity="error">Al parecer no has iniciado sesión, por favor inicia el proceso</Alert>
    )
}
